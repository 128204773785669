<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Productos - Categorías</h4>
            <div class="small text-muted">Administración de categorias y sub-categorias</div>
          </b-col>
          
          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Categoría">
              <i class="fa fa-plus"></i> Agregar
            </b-button>
          </b-col>                    
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row class="mb-2">
          <b-col sm="4">
            <v-select :options="arr.filters.type" v-model="filters.type" placeholder="Unidad de negocio" :multiple="false" :select-on-tab="true"></v-select>                
          </b-col>
          <b-col sm="4">
            <b-form-input type="text" size="sm" placeholder="Ingrese su busqueda" v-model="filters.name" @keyup.enter="load()"></b-form-input>
          </b-col>          
          <b-col sm="4">
            <b-button variant="dark" size="sm" @click="load()">
              <i class="fa fa-filter"></i> Filtrar
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    selectable
                    select-mode="single"
                    @row-selected="onRowSelected"                    
                    :busy="table.isBusy"  
                    v-if="table.items.length || table.isBusy">                       


                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Cargando...</strong>
                  </div>
                </template>

                <template v-slot:row-details="row">
                  <b-card>
                    <b-row>
                      <b-col md="12">
                        <b-row>
                          <b-col md="6">
                            <div class="product-categories-titulo-subcategory mb-2">
                              <b>Categoría:</b> {{row.item.name}}
                            </div>
                          </b-col>
                          <b-col md="6">
                            <b-button @click="addSub(row.item)" type="button" variant="secondary" size="sm" class="btn-pill float-right ml-2 mb-1" v-b-tooltip.hover title="Agregar Sub-Categoría">
                              <i class="fa fa-plus"></i> Agregar
                            </b-button>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="12">
                            <b-table class="mb-0"
                                    responsive="sm"
                                    head-variant="dark"
                                    :hover="true"
                                    :small="true"
                                    :fixed="false"
                                    :sort-by.sync="tableSub.sortBy"
                                    :sort-desc.sync="tableSub.sortDesc"                                       
                                    :items="tableSub.items"
                                    :fields="tableSub.fields"                                                                
                                    v-if="tableSub.items.length">   
                              
                              <template v-slot:cell(id)="data">
                                <b>{{data.item.id}}</b>
                              </template>

                              <template v-slot:cell(name)="data">
                                <div>
                                  {{data.item.name}}
                                </div>
                              </template>

                              <template v-slot:cell(level1)="data">
                                <div v-if="data.item.children.length">                                  
                                  {{data.item.name}}
                                </div>
                                <div v-else>
                                  <div v-if="data.item.parent">
                                    {{data.item.parent.name}}
                                  </div>
                                  <div v-else>
                                    {{data.item.name}}
                                  </div>
                                </div>
                              </template>

                              <template v-slot:cell(level2)="data">
                                <div v-if="!data.item.children.length">
                                  <div v-if="data.item.parent">
                                    {{data.item.name}}
                                  </div>                                  
                                </div>                                
                              </template>                              

                              <template v-slot:cell(fields_required)="data">
                                <div v-if="!data.item.children.length">   
                                  <div v-if="data.item.fields_required">
                                    <div v-if="data.item.fields_required.length">
                                      <b-badge variant="secondary" 
                                              v-for="(element, index) in data.item.fields_required" 
                                              v-bind:key="index"
                                              class="mr-1">
                                        {{element}}
                                      </b-badge>
                                    </div>
                                    <div v-else class="text-warning">
                                      <b>No tiene campos requeridos</b>
                                    </div>
                                  </div>
                                  <div v-else class="text-warning">
                                    <b>No tiene campos requeridos</b>
                                  </div>                                
                                </div>                                                            
                              </template>

                              <template v-slot:cell(observations)="data">                                   
                                <div v-if="!data.item.children.length">               
                                  <div class="products-categories-tablesub-observations" v-if="data.item.count_products">
                                    <b>Tiene ({{data.item.count_products}}) productos relacionados</b>
                                  </div>
                                  <div v-else class="text-warning">
                                    <b>No tiene productos relacionados</b>
                                  </div>
                                </div>
                              </template>                              
                        
                              <template v-slot:cell(order)="data">
                                <b-badge variant="secondary">{{data.item.order}}</b-badge>
                              </template>

                              <template v-slot:cell(show_print)="data">
                                <b-icon v-if="data.item.show_print" class="h5 mb-0" variant="success" icon="eye" title="Imprime"></b-icon>
                                <b-icon v-else class="h5 mb-0" variant="danger" icon="eye-slash" title="NO Imprime"></b-icon>
                              </template>                              

                              <template v-slot:cell(f_action)="data">
                                <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                                  <b-dropdown-item @click="editSub(data.item)">
                                    <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                                  </b-dropdown-item>
                                  <b-dropdown-item @click="removeSub(data.item)">
                                    <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                                  </b-dropdown-item>
                                </b-dropdown>
                              </template>

                            </b-table>
                            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-card>
                </template>

                <template v-slot:cell(observations)="data">                  
                  <div class="products-categories-table-observations" v-if="data.item.subcategorys.length">
                    <b>Tiene ({{data.item.subcategorys.length}}) sub-categorias relacionadas</b>
                  </div>
                  <div v-else class="text-warning">
                    <b>No tiene sub-categorias relacionadas</b>
                  </div>
                </template>

                <template v-slot:cell(type)="data">
                  <div v-if="data.item.type=='titolovende'">
                    <b-badge variant="success">TITO LO VENDE</b-badge>
                  </div>
                  <div v-if="data.item.type=='titoloconstruye'">
                    <b-badge variant="warning">TITO LO CONSTRUYE</b-badge>                    
                  </div>
                </template>

                <template v-slot:cell(id)="data">
                  <b>{{data.item.id}}</b>
                </template>

                <template v-slot:cell(image)="data">
                  <b-avatar :src="data.item.image"
                            v-if="data.item.image">
                  </b-avatar>               
                </template>

                <template v-slot:cell(order)="data">
                  <b-badge variant="dark">{{data.item.order}}</b-badge>
                </template>

                <template v-slot:cell(show_print)="data">
                  <b-icon v-if="data.item.show_print" class="h5 mb-0" variant="success" icon="eye" title="Imprime"></b-icon>
                  <b-icon v-else class="h5 mb-0" variant="danger" icon="eye-slash" title="NO Imprime"></b-icon>
                </template>     

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>          
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-tabs>
          <b-tab title="General">
            <b-row>
              <b-col md="12">
                <b-form-group label="Unidad de Negocio">
                  <b-form-select v-model="crud.form.type" :options="arr.type"></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name"
                                required
                                placeholder="Ingresar un nombre">
                  </b-form-input>
                </b-form-group>
              </b-col>  

              <b-col md="12">                
                <b-form-group label="Imagen">
                  <Imagen :images="crud.form.image" 
                          @load-image="crud.form.image = $event" 
                          typeImage="Imagen" 
                          :multipleImage="false" 
                          :compressImage="true"/>
                </b-form-group>                            
              </b-col>      
            </b-row>
          </b-tab>
          <b-tab title="Impresion Listas de Precios" v-if="showPrices">
            <b-row>
              <b-col md="12" class="mb-4">
                <b-form-checkbox v-model="crud.form.show_print" 
                                  switch 
                                  size="sm" 
                                  class="pull-left">
                  Mostrar en impresión de lista de precios.
                </b-form-checkbox>                   
              </b-col>              
              <b-col md="12">
                <b-form-group label="Orden" description="Valor utilizado para ordenar en la impresión de listas de precios">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.form.order"
                                required
                                placeholder="Ingresar un orden numérico">
                  </b-form-input>
                </b-form-group>
              </b-col>    
            </b-row>
          </b-tab>
        </b-tabs>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD SUB -->
      <b-modal v-model="modal.formSub.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formSub.title}}
        </div>

        <b-tabs>
          <b-tab title="General">
            <b-row>
              <b-col md="12">
                <FindObject type="productsCategory" 
                            @select-object="loadProductsCategory($event)" 
                            :valueID="crud.formSub.category_id"/>
              </b-col>    
              <b-col md="12" v-if="parameters.hasMultiLevelCategory">
                <FindObject type="productsSubCategoryParent"
                            tag="Padre" 
                            :where="crud.formSub.parentWhere"
                            @select-object="loadProductsParent($event)" 
                            :valueID="crud.formSub.parent_id"/>
              </b-col>                          
              <b-col md="12">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.formSub.name"
                                required
                                placeholder="Ingresar un nombre">
                  </b-form-input>
                </b-form-group>
              </b-col>  
              <b-col md="12">
                <b-form-group label="Campos Requeridos" description="Estos campos se pediran en la carga de productos para esta categoría">
                  <b-form-tags v-model="crud.formSub.fields_required"
                                tag-variant="secondary"
                                tag-pills
                                size="sm"
                                placeholder="Agregar Campos"
                                separator=" ">
                  </b-form-tags>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab title="Impresion Listas de Precios" v-if="showPrices">
            <b-row>
              <b-col md="12" class="mb-4">
                <b-form-checkbox v-model="crud.formSub.show_print" 
                                  switch 
                                  size="sm" 
                                  class="pull-left">
                  Mostrar en impresión de lista de precios.
                </b-form-checkbox>                   
              </b-col>               
              <b-col md="12">
                <b-form-group label="Orden" description="Valor utilizado para ordenar en la impresión de listas de precios">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.formSub.order"
                                required
                                placeholder="Ingresar un orden numérico">
                  </b-form-input>
                </b-form-group>
              </b-col>                      
            </b-row>
          </b-tab>
        </b-tabs>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formSub.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveSub()">Guardar</b-button>          
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'
  import Imagen from '@/components/inc/image/image' 
  import Param from '@/config/parameters'

  export default {
    components: {
      FindObject,
      Imagen,
    },      
    data: () => {
      return {      
        access: {
          module_id: Modules.PRODUCTOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudCategories',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [],
          isBusy: false,
          currentPage: 1,
          perPage: 50,
        },
        tableSub : {          
          items: [],
          fields: [],
          filter: null,
          currentPage: 1,
          perPage: 50,
        },         
        crud: {
          form: {
            id: 0,
            name: '',
            image : [],
            order: 0,
            show_print: true,
            type:'titolovende'
          }, 
          formSub: {
            id: 0,
            category_id: 0,
            category: null,
            parent_id: 0,
            parent: null,
            name: '',     
            fields_required: [],
            parentWhere: [],
            order: 0,
            show_print: true,
          },                     
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          formSub: {
            active: false,
            title: ''
          },           
        },
        arr: {
          category: [],
          type: [              
            {value:'titolovende', text: 'TITO LO VENDE'},
            {value:'titoloconstruye', text: 'TITO LO CONSTRUYE'},                       
          ],
          filters: {
            type: [
              {code:'titolovende', label: 'TITO LO VENDE'},
              {code:'titoloconstruye', label: 'TITO LO CONSTRUYE'},                       
            ],
          },
        },
        filters: {
          type: [],
          name: '',
        },
        parameters: {
          hasMultiLevelCategory: Helper.hasParametersAccess(Param.P20),
        },                                 
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {      
      this.loadFieldTable()
      this.load()
    },
    computed: {
      showPrices() {
        var status = false
        var modulesActive = Session.getSession().auth.user.permissions.modules
        modulesActive.forEach(element => {          
          if(element.id == Modules.PRECIOS) {                          
            status = true                             
          }
        }) 
        return status       
      }
    },
    methods: {
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {      
        this.arr.category.forEach((element, index) => {
          if(item.length){
            if(element.id == item[0].id) {
              this.openDetail(index)
            }
          }
        }) 
      },     
      loadFieldTable () {
        // table
        this.table.fields.push({key: 'id', label: 'ID', class: 'align-middle'})
        this.table.fields.push({key: 'type', label: 'Unidad de Negocio', class: 'align-middle'})
        this.table.fields.push({key: 'name', label: 'Nombre', class: 'align-middle'})
        this.table.fields.push({key: 'image', label: 'Imagen', class: 'text-center align-middle'})
        this.table.fields.push({key: 'observations', label: 'Observaciones', class: 'align-middle'})
        if(this.showPrices) {
          this.tableSub.sortBy = 'order'
          this.tableSub.sortDesc = false                    
          this.table.fields.push({key: 'order', label: 'Orden', class: 'text-center align-middle', sortable:true})
        }
        this.table.fields.push({key: 'show_print', label: '', class: 'text-center align-middle'})
        this.table.fields.push({key: 'f_action', label:'', class: 'align-middle'})

        // subtable
        this.tableSub.fields.push({key: 'id', label: 'ID'})        
        if(this.parameters.hasMultiLevelCategory) {
          this.tableSub.fields.push({key: 'level1', label: 'Nivel 1'})
          this.tableSub.fields.push({key: 'level2', label: 'Nivel 2'})
        } else {
          this.tableSub.fields.push({key: 'name', label: 'Nombre'})
        }        
        this.tableSub.fields.push({key: 'fields_required', label: 'Campos Requeridos'})
        this.tableSub.fields.push({key: 'observations', label: 'Observaciones'})
        if(this.showPrices) {
          this.tableSub.sortBy = 'order'
          this.tableSub.sortDesc = false          
          this.tableSub.fields.push({key: 'order', label: 'Orden', class: 'text-center align-middle', sortable:true})
        }        
        this.tableSub.fields.push({key: 'show_print', label: '', class: 'text-center align-middle'})
        this.tableSub.fields.push({key: 'f_action', label:''})
      },      
      openDetail(index) {                                
        this.table.rowSelected = index
        this.load()
      },       
      load() {
        this.table.isBusy = true

        var result = serviceAPI.filtrarCategoria(this.filters)

        result.then((response) => {
          var data = response.data
          this.table.items = data
          this.arr.category = data
          
          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true       
            
            if(this.table.items[this.table.rowSelected].subcategorys) {
              if(this.parameters.hasMultiLevelCategory) {
                this.tableSub.items = this.reorderSub(this.table.items[this.table.rowSelected].subcategorys)
              } else {
                this.tableSub.items = this.table.items[this.table.rowSelected].subcategorys
              }              
            }
          }   
          
          this.table.isBusy = false
        })
        .catch(error => {
          this.table.isBusy = false
          this.$awn.alert(Error.showError(error))
        });        
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.name = '' 
        this.crud.form.image = []
        this.crud.form.order = 0
        this.crud.form.show_print = true
        this.crud.form.type='titolovende'

        this.modal.form.title = "Nueva Categoría"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name           
        this.crud.form.image = item.image
        this.crud.form.order = item.order
        this.crud.form.show_print = item.show_print
        this.crud.form.type = item.type

        if(item.image.length>0) {
          this.crud.form.image = [{ path: item.image }]
        } else {
          this.crud.form.image = []
        }

        this.modal.form.title = "Editar Categoría"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Categoría',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarCategoria(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();

        var image = new FormData();
        this.crud.form.image.forEach((value, key) => {
          image.append(key, value.path);
        })

        if (this.crud.form.id) {
          var result = serviceAPI.editarCategoria(JSON.stringify(this.crud.form), image);
        } else {
          var result = serviceAPI.agregarCategoria(JSON.stringify(this.crud.form), image);
        }
        
        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      addSub(item) {
        this.crud.formSub.id = 0
        this.crud.formSub.category_id = item.id        
        this.crud.formSub.name = '' 
        this.crud.formSub.fields_required = []
        this.crud.formSub.order = 0
        this.crud.formSub.show_print = true
        
        this.crud.formSub.parent_id = null
        this.crud.formSub.parentWhere = [{field: 'category_id', condition: item.id}];

        this.modal.formSub.title = "Nueva Sub-Categoría"
        this.modal.formSub.active = true
      },
      editSub(item) {
        this.crud.formSub.id = item.id
        this.crud.formSub.category_id = item.category_id
        this.crud.formSub.name = item.name           
        this.crud.formSub.fields_required = item.fields_required
        this.crud.formSub.order = item.order
        this.crud.formSub.show_print = item.show_print

        this.crud.formSub.parent_id = item.parent_id
        this.crud.formSub.parentWhere = [{field: 'category_id', condition: item.category_id}];

        this.modal.formSub.title = "Editar Sub-Categoría"
        this.modal.formSub.active = true
      },
      removeSub(item) {
        this.crud.formSub.id = item.id
        this.crud.formSub.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.formSub.id + ') - '+ this.crud.formSub.name + '?', {
          title: 'Borrar Sub-Categoría',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarSubcategoria(this.crud.formSub.id);

            result.then((response) => {      
              this.modal.formSub.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      saveSub() {
        let loader = this.$loading.show();
        if (this.crud.formSub.id) {
          var result = serviceAPI.editarSubcategoria(this.crud.formSub);
        } else {
          var result = serviceAPI.agregarSubcategoria(this.crud.formSub);
        }

        result.then((response) => {
          this.modal.formSub.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      reorderSub(arr) {        
        var arrTMP = []
        if(arr) {
          arr.forEach((element, index) => {            
            if(!element.parent) {
              arrTMP.push(element) 
            }
          });
          
          var arrFinal = []
          if(arrTMP) {                                       
            arrTMP.forEach(element => {                          
              arrFinal.push(element) 
              var searchResult = this.searchSub(arr, element.id)
              if(searchResult) {
                searchResult.forEach(element1 => {
                  arrFinal.push(element1)  
                });
              }
            });
          }
        }
        return arrFinal
      },
      searchSub(arr, parent_id) {
        var arrResult = []
        arr.forEach(element => {
          if(element.parent_id == parent_id) {
            arrResult.push(element)
          }
        });        
        return arrResult
      },

      loadProductsCategory (object) {
        if(object){
          this.crud.formSub.category = object
          this.crud.formSub.category_id = object.id
        } else {
          this.crud.formSub.category = null
          this.crud.formSub.category_id = 0
        }
      }, 
      loadProductsParent (object) {        
        if(object){
          this.crud.formSub.parent = object
          this.crud.formSub.parent_id = object.id
        } else {
          this.crud.formSub.parent = null
          this.crud.formSub.parent_id = 0
        }
      },      
    }    
  }
</script>
<style>
  .product-categories-titulo-subcategory {
    font-size: 17px;
  }
  .products-categories-table-observations {
    color: darkblue;
  }
  .products-categories-tablesub-observations {
    color: darkred;
  }
</style>
<style scoped>
  .badge {
    font-size: 100%;
  }
</style>